import Image from 'next/image';

export default function Home() {
  return (
    <>
      <Image
        src="/images/anygift-logo-landscape.png"
        alt="logo"
        width={202}
        height={200}
        className="my-8"
      />
    </>
  );
}
